// src/components/PctMedicamentList.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Typography,
  Box,
  TextField,
  TablePagination,
} from '@mui/material';
import PctMedicamentForm from './PctMedicamentForm';
import { useNavigate } from 'react-router-dom';

const PctMedicamentList = () => {
  const [pctMedicaments, setPctMedicaments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentPctMedicament, setCurrentPctMedicament] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPctMedicaments, setTotalPctMedicaments] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPctMedicaments();
  }, [page, rowsPerPage, searchQuery]);

  const fetchPctMedicaments = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/pct_medicaments', {
        params: { 
          page: page + 1, // Backend is 1-indexed, so add 1
          limit: rowsPerPage,
          search: searchQuery, // Pass search query to the backend
        },
        headers: { 
          "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
          Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
        },
      });
      setPctMedicaments(response.data.pctMedicaments);
      setTotalPctMedicaments(response.data.totalPctMedicaments);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddClick = () => {
    setCurrentPctMedicament(null);
    setOpen(true);
  };

  const handleUpdateClick = (pctMedicament) => {
    setCurrentPctMedicament(pctMedicament);
    setOpen(true);
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`/pct_medicaments/${id}`, {
        headers: { 
          "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
          Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
        },
      });
      fetchPctMedicaments();
    } catch (error) {
      console.error('Error deleting pctMedicament:', error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentPctMedicament(null);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset page to 0 when search query changes
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box padding={2}>
      <Typography variant="h4" gutterBottom>
        PctMedicaments
      </Typography>
      <Button variant="contained" color="primary" onClick={handleAddClick} sx={{ mb: 2 }}>
        Add PctMedicament
      </Button>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={handleSearchChange}
      />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Specialite</TableCell>
                <TableCell>Fournisseur</TableCell>
                <TableCell>DCI1</TableCell>
                <TableCell>DCI2</TableCell>
                <TableCell>DCI3</TableCell>
                <TableCell>DCI4</TableCell>
                <TableCell>DCI5</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pctMedicaments.map((pctMedicament) => (
                <TableRow key={pctMedicament._id}>
                  <TableCell>{pctMedicament.specialite}</TableCell>
                  <TableCell>{pctMedicament.fournisseur}</TableCell>
                  <TableCell>{pctMedicament.dci1}</TableCell>
                  <TableCell>{pctMedicament.dci2}</TableCell>
                  <TableCell>{pctMedicament.dci3}</TableCell>
                  <TableCell>{pctMedicament.dci4}</TableCell>
                  <TableCell>{pctMedicament.dci5}</TableCell>
                  <TableCell align="right">
                    <ButtonGroup variant="outlined" size="small">
                      <Button onClick={() => handleUpdateClick(pctMedicament)} color="primary">
                        Update
                      </Button>
                      <Button onClick={() => handleDeleteClick(pctMedicament._id)} color="error">
                        Delete
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalPctMedicaments}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </TableContainer>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{currentPctMedicament ? 'Update PctMedicament' : 'Add PctMedicament'}</DialogTitle>
        <DialogContent>
          <PctMedicamentForm
            pctMedicament={currentPctMedicament}
            onClose={handleClose}
            onSave={fetchPctMedicaments}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PctMedicamentList;

// src/components/PctMedicamentForm.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button } from '@mui/material';

const PctMedicamentForm = ({ pctMedicament, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    specialite: '',
    code_produit: '',
    fournisseur: '',
    dci1: '',
    amm: '',
    dateamm: '',
    designation_classe: '',
    disp_part: '',
    dci2: '',
    dci3: '',
    dci4: '',
    dci5: '',
  });

  useEffect(() => {
    if (pctMedicament) {
      setFormData(pctMedicament);
    }
  }, [pctMedicament]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (pctMedicament) {
        await axios.put(`/pct_medicaments/${pctMedicament._id}`, formData, {
          headers: { 
            "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
            Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
          },
        });
      } else {
        await axios.post('/pct_medicaments', formData, {
          headers: { 
            "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
            Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
          },
        });
      }
      onSave();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField label="Specialite" name="specialite" value={formData.specialite} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Code Produit" name="code_produit" value={formData.code_produit} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Fournisseur" name="fournisseur" value={formData.fournisseur} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="DCI1" name="dci1" value={formData.dci1} onChange={handleChange} fullWidth margin="normal" />
      {/* Add more fields as necessary */}
      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </form>
  );
};

export default PctMedicamentForm;

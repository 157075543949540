import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import UpdateMedicamentVariantForm from './UpdateMedicamentVariantForm';
import AddMedicamentVariantForm from './AddMedicamentVariantForm';
import { useParams } from 'react-router-dom';

const MedicamentVariantList = () => {
  const { medicamentId, specialite } = useParams();
  const [variants, setVariants] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentVariant, setCurrentVariant] = useState(null);

  useEffect(() => {
    fetchVariants();
  }, [medicamentId, open]);

  const fetchVariants = async () => {
    try {
      const response = await axios.get(`medicament_variants/${medicamentId}`, {
        headers: { 
          "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
          Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
        },
      });
      setVariants(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddClick = () => {
    setCurrentVariant(null);
    setOpen(true);
  };

  const handleUpdateClick = (variant) => {
    setCurrentVariant(variant);
    setOpen(true);
  };

  const handleDeleteClick = async (id, medicamentId, pathologyId) => {
    try {
      await axios.delete(`medicament_variants/${id}`, {
        headers: {
          "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
          Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
        },
        data: {
          medicamentId,
          pathologyId
        }
      });
    } catch (error) {
      console.error('Error deleting medicament:', error);
    }
  };
  

  const handleClose = () => {
    setOpen(false);
    setCurrentVariant(null);
  };

  return (
    <div>
      <h1>{specialite}: Medicament Variants</h1>
      <Button variant="contained" color="primary" onClick={handleAddClick}>
        Add Variant
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Medoc ou Complement ou Phyto</TableCell>
              <TableCell>Dose</TableCell>
              <TableCell>Frequence</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {variants.map((variant) => (
              <TableRow key={variant._id}>
                <TableCell>{variant.medoc_ou_complement_ou_phyto}</TableCell>
                <TableCell>{variant.dose}</TableCell>
                <TableCell>{variant.frequence}</TableCell>
                <TableCell>
                  <Button variant="outlined" color="primary" onClick={() => handleUpdateClick(variant)}>
                    Update
                  </Button>
                  <Button onClick={() => handleDeleteClick(variant._id, variant.medicamentId, variant.pathologyId)} color="error">
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentVariant ? 'Update Variant' : 'Add Variant'}</DialogTitle>
        {currentVariant? (
          <DialogContent>
          <UpdateMedicamentVariantForm
            variant={currentVariant}
            medicamentId={medicamentId}
            onClose={handleClose}
            onSave={fetchVariants}
          />
        </DialogContent>
      ) : (
      <DialogContent>
          <AddMedicamentVariantForm
            medicamentId={medicamentId}
            onClose={handleClose}
            onSave={fetchVariants}
          />
        </DialogContent>
      )}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MedicamentVariantList;

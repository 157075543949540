import React, { useState, useEffect } from 'react';
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, Menu, MenuItem, IconButton, Icon } from '@mui/material';
import axios from 'axios';

function MedicamentSuggestions() {
    const [medicaments, setMedicaments] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMedicamentId, setSelectedMedicamentId] = useState(null);
  
    const handleClick = (event, userId) => {
      setAnchorEl(event.currentTarget);
      setSelectedMedicamentId(userId);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      setSelectedMedicamentId(null);
    };

    const handleDelete = async () => {
      setMedicaments(medicaments => medicaments.filter(medicament => medicament._id !== selectedMedicamentId));
      await axios.post("delete_medicine", { medicineId: selectedMedicamentId}, {
        headers: { 
          "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
          Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
        },
      });
      handleClose();
    };
  
    useEffect(() => {
      const fetchMedicamentSuggestions = async () => {
        try {
          const response = await axios.get("get_medicament_suggestions", {
            headers: { 
              "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
              Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
            },
          });
          setMedicaments([...response.data].reverse());
        } catch (error) {
          console.log(error);
        }
      }
  
      fetchMedicamentSuggestions();
    }, []);
  
    return (
      <div>
        <Typography variant="h4">Suggestion de médicaments</Typography>
  
        {/* Medicaments Table */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Spécialité</TableCell>
              <TableCell>Forme</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Prix</TableCell>
              <TableCell>Indication</TableCell>
              <TableCell>Utilisateur</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {medicaments.map((medicament, idx) => (
              <TableRow key={idx}>
                <TableCell>{medicament.specialite}</TableCell>
                <TableCell>{medicament.forme}</TableCell>
                <TableCell>{medicament.medoc_ou_complement_ou_phyto}</TableCell>
                <TableCell>{medicament.prix}</TableCell>
                <TableCell>{medicament.indication}</TableCell>
                <TableCell>{medicament?.userId?.username}</TableCell>
                <IconButton onClick={(e) => handleClick(e, medicament._id)}>
                  <Icon>...</Icon>
                </IconButton>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
      </div>
    );
  }

  export default MedicamentSuggestions;
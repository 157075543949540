// src/components/InteractionList.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Typography,
  Box,
  TextField,
  TablePagination,
} from '@mui/material';
import InteractionForm from './InteractionForm';
import { useNavigate } from 'react-router-dom';

const InteractionList = () => {
  const [interactions, setInteractions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentInteraction, setCurrentInteraction] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalInteractions, setTotalInteractions] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchInteractions();
  }, [page, rowsPerPage, searchQuery]);

  const fetchInteractions = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/interactions', {
        params: { 
          page: page + 1, // Backend is 1-indexed, so add 1
          limit: rowsPerPage,
          search: searchQuery, // Pass search query to the backend
        },
        headers: {
          "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
          "x-api-key": '078794VPC0001',
          Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
        },
      });
      setInteractions(response.data.interactions);
      setTotalInteractions(response.data.totalInteractions);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddClick = () => {
    setCurrentInteraction(null);
    setOpen(true);
  };

  const handleUpdateClick = (interaction) => {
    setCurrentInteraction(interaction);
    setOpen(true);
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`/interactions/${id}`, {
        headers: { 
          "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
          "x-api-key": '078794VPC0001',
          Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
        },
      });
      fetchInteractions();
    } catch (error) {
      console.error('Error deleting interaction:', error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentInteraction(null);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset page to 0 when search query changes
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box padding={2}>
      <Typography variant="h4" gutterBottom>
        Interactions
      </Typography>
      <Button variant="contained" color="primary" onClick={handleAddClick} sx={{ mb: 2 }}>
        Add Interaction
      </Button>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={handleSearchChange}
      />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Protagoniste 1</TableCell>
                <TableCell>Protagoniste 2</TableCell>
                <TableCell>Mécanisme d'interaction</TableCell>
                <TableCell>SL0</TableCell>
                <TableCell>SL1</TableCell>
                <TableCell>SL2</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {interactions.map((interaction) => (
                <TableRow key={interaction._id}>
                  <TableCell>{interaction.protagoniste_1}</TableCell>
                  <TableCell>{interaction.protagoniste_2}</TableCell>
                  <TableCell>{interaction.interaction_mecanism}</TableCell>
                  <TableCell>{interaction.severity_level_0}</TableCell>
                  <TableCell>{interaction.severity_level_1}</TableCell>
                  <TableCell>{interaction.severity_level_2}</TableCell>
                  <TableCell align="right">
                    <ButtonGroup variant="outlined" size="small">
                      <Button onClick={() => handleUpdateClick(interaction)} color="primary">
                        Update
                      </Button>
                      <Button onClick={() => handleDeleteClick(interaction._id)} color="error">
                        Delete
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalInteractions}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </TableContainer>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{currentInteraction ? 'Update Interaction' : 'Add Interaction'}</DialogTitle>
        <DialogContent>
          <InteractionForm
            interaction={currentInteraction}
            onClose={handleClose}
            onSave={fetchInteractions}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InteractionList;

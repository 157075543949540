// src/components/InteractionForm.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button } from '@mui/material';

const InteractionForm = ({ interaction, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    protagoniste_1: '',
    protagoniste_2: '',
    between_drugs: '',
    description: '',
    interaction_mecanism: '',
    severity_level_0: '',
    severity_level_0_info: '',
    severity_level_1: '',
    severity_level_1_info: '',
    severity_level_2: '',
    severity_level_2_info: '',
  });

  useEffect(() => {
    if (interaction) {
      setFormData(interaction);
    }
  }, [interaction]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (interaction) {
        await axios.put(`/interactions/${interaction._id}`, formData, {
          headers: { 
            "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
            "x-api-key": '078794VPC0001',
            Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
          },
        });
      } else {
        await axios.post('/interactions', formData, {
          headers: { 
            "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
            "x-api-key": '078794VPC0001',
            Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
          },
        });
      }
      onSave();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField label="Protagoniste 1" name="protagoniste_1" value={formData.protagoniste_1} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Protagoniste 2" name="protagoniste_2" value={formData.protagoniste_2} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Between Drugs" name="between_drugs" value={formData.between_drugs} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Description" name="description" value={formData.description} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Interaction Mechanism" name="interaction_mecanism" value={formData.interaction_mecanism} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Severity Level 0" name="severity_level_0" value={formData.severity_level_0} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Severity Level 0 Info" name="severity_level_0_info" value={formData.severity_level_0_info} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Severity Level 1" name="severity_level_1" value={formData.severity_level_1} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Severity Level 1 Info" name="severity_level_1_info" value={formData.severity_level_1_info} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Severity Level 2" name="severity_level_2" value={formData.severity_level_2} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Severity Level 2 Info" name="severity_level_2_info" value={formData.severity_level_2_info} onChange={handleChange} fullWidth margin="normal" />
      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </form>
  );
};

export default InteractionForm;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText } from '@mui/material';

const AddMedicamentVariantForm = ({ medicamentId, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    medoc_ou_complement_ou_phyto: '',
    dose: '',
    frequence: '',
    duree_traitement: '',
    conseil_utilisation: '',
    tableau_ou_pas: '',
    age_specifique_medic: '',
    min_age_enfant: '',
    max_age_enfant: '',
    mise_en_garde: '',
    sexe_specifique_medic: '',
    femme_enceinte: '',
    allaitement: '',
    hta: '',
    diabete: '',
    epilepsie: '',
    tags: '',
    legal_class: '',
    entry_type: '',
    rules: '',
    traitement_appoint: '',
    medicamentId: medicamentId,
    pathologyIds: [],
  });

  const [pathologies, setPathologies] = useState([]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      medicamentId: medicamentId,
    }));

    // Fetch pathologies
    const fetchPathologies = async () => {
      try {
        const response = await axios.get('pathologies', {
            headers: { 
              "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
              Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
            },
          });;
        setPathologies(response.data);
      } catch (error) {
        console.error('Error fetching pathologies:', error);
      }
    };

    fetchPathologies();
  }, [medicamentId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePathologyChange = (event) => {
    const { value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      pathologyIds: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.pathologyIds.length === 0) {
      alert("Please select at least one pathology.");
      return;
    }

    try {
      await axios.post("medicament_variants", formData, {
        headers: {
          "x-access-token":
            localStorage.getItem("token") || sessionStorage.getItem("token"),
          Authorization: `Bearer ${
            localStorage.getItem("token") || sessionStorage.getItem("token")
          }`,
        },
      });
      onSave();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField label="Medoc ou Complement ou Phyto" name="medoc_ou_complement_ou_phyto" value={formData.medoc_ou_complement_ou_phyto} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Dose" name="dose" value={formData.dose} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Frequence" name="frequence" value={formData.frequence} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Duree Traitement" name="duree_traitement" value={formData.duree_traitement} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Conseil Utilisation" name="conseil_utilisation" value={formData.conseil_utilisation} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Tableau ou Pas" name="tableau_ou_pas" value={formData.tableau_ou_pas} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Age Specifique Medic" name="age_specifique_medic" value={formData.age_specifique_medic} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Min Age Enfant" name="min_age_enfant" value={formData.min_age_enfant} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Max Age Enfant" name="max_age_enfant" value={formData.max_age_enfant} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Sexe Specifique Medic" name="sexe_specifique_medic" value={formData.sexe_specifique_medic} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Femme Enceinte" name="femme_enceinte" value={formData.femme_enceinte} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Allaitement" name="allaitement" value={formData.allaitement} onChange={handleChange} fullWidth margin="normal" />
      <FormControl fullWidth margin="normal">
        <InputLabel>Diabète</InputLabel>
        <Select
          label="Diabète"
          name="diabete"
          value={formData.diabete || ""}
          onChange={handleChange}
        >
          <MenuItem value="">-</MenuItem>
          <MenuItem value="oui">Oui</MenuItem>
          <MenuItem value="non">Non</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>HTA</InputLabel>
        <Select
          label="HTA"
          name="hta"
          value={formData.hta || ""}
          onChange={handleChange}
        >
          <MenuItem value="">-</MenuItem>
          <MenuItem value="oui">Oui</MenuItem>
          <MenuItem value="non">Non</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Épilepsie</InputLabel>
        <Select
          label="Épilepsie"
          name="epilepsie"
          value={formData.epilepsie || ""}
          onChange={handleChange}
        >
          <MenuItem value="">-</MenuItem>
          <MenuItem value="oui">Oui</MenuItem>
          <MenuItem value="non">Non</MenuItem>
        </Select>
      </FormControl>
      <TextField label="Mise En Garde" name="mise_en_garde" value={formData.mise_en_garde} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Tags" name="tags" value={formData.tags} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Legal Class" name="legal_class" value={formData.legal_class} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Entry Type" name="entry_type" value={formData.entry_type} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Rules" name="rules" value={formData.rules} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Traitement Appoint" name="traitement_appoint" value={formData.traitement_appoint} onChange={handleChange} fullWidth margin="normal" />
      <FormControl fullWidth margin="normal">
        <InputLabel id="pathology-label">Pathologies</InputLabel>
        <Select
          labelId="pathology-label"
          name="pathologyIds"
          multiple
          value={formData.pathologyIds}
          onChange={handlePathologyChange}
          renderValue={(selected) =>
            selected
              .map((id) => {
                const pathology = pathologies.find((p) => p._id === id);
                return pathology
                  ? `${pathology.appareilId.appareil} - ${pathology.pathologie}`
                  : '';
              })
              .join(', ')
          }
        >
          {pathologies.map((pathology) => (
            <MenuItem key={pathology._id} value={pathology._id}>
              <Checkbox checked={formData.pathologyIds.includes(pathology._id)} />
              <ListItemText
                primary={`${pathology.appareilId.appareil} - ${pathology.pathologie}`}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button type="submit" variant="contained" color="primary" disabled={formData.pathologyIds.length === 0}>
        Save
      </Button>
    </form>
  );
};

export default AddMedicamentVariantForm;

import React, { useEffect, useState } from "react";
import axios from "axios";

import "./PathologyCard.css";

const PathologyCard = ({ isOpen, togglePopup, selectedPathology }) => {

  const [formData, setFormData] = useState({
    pathologie: "",
    patho_associées: "",
    red_flags: "",
  });

  useEffect(() => {
    if (selectedPathology) {
      setFormData({
        pathologie: selectedPathology.pathologie || "",
        patho_associées: selectedPathology.patho_associées || "",
        red_flags: selectedPathology.red_flags || "",
      });
    }
  }, [selectedPathology]);

  if (!isOpen || !selectedPathology) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePathologyUpdate = async () => {
    try {
      const response = await axios.patch(
        `/pathologies/${selectedPathology._id}`,
        formData,
        {
            headers: {
                "x-access-token": localStorage.getItem("token") || sessionStorage.getItem("token"),
                Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
            },
        }
    );
      if (response.status === 200) {
        togglePopup();
      }
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  return (
    <div className="popup-overlay" onClick={togglePopup}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <div className="pathology-details">
          <label>
            <strong>Nom de la pathologie:</strong>
            <input
              type="text"
              name="pathologie"
              value={formData.pathologie}
              onChange={handleChange}
            />
          </label>
          <p>
            <strong>Appareil:</strong> {selectedPathology.appareilId.appareil}
          </p>
          <p>
            <strong>Age spécifique:</strong>{" "}
            {selectedPathology.age_specifique_pathologie === 'A'? 'Adulte' : selectedPathology.age_specifique_pathologie === 'E'? 'Enfant' : 'unset'}
          </p>
          <p>
            <strong>Sexe spécifique:</strong>{" "}
            {selectedPathology.sexe_specifique_pathologie === 'H'? 'Homme' : selectedPathology.sexe_specifique_pathologie === 'F'? 'Femme' : 'unset'}
          </p>
          <label>
            <strong>Pathologies associées:</strong>
            <input
              type="text"
              name="patho_associées"
              value={formData.patho_associées}
              onChange={handleChange}
            />
          </label>
          <label>
            <strong>Red flags:</strong>
            <input
              type="text"
              name="red_flags"
              value={formData.red_flags}
              onChange={handleChange}
            />
          </label>
          <p>
            <strong>Green flags:</strong> {selectedPathology.green_flags}
          </p>
          <p>
            <strong>Min Age:</strong> {selectedPathology.min_age_pathologie}
          </p>
          <p>
            <strong>Max Age:</strong> {selectedPathology.max_age_pathologie}
          </p>
          <p>
            <strong>Créé à:</strong>{" "}
            {new Date(selectedPathology.createdAt).toLocaleString('en-GB')}
          </p>
          <p>
            <strong>Dernière mise à jour:</strong>{" "}
            {new Date(selectedPathology.updatedAt).toLocaleString('en-GB')}
          </p>
        </div>
        <div className="button-group">
          <button className="close-popup" onClick={togglePopup}>
            Fermer
          </button>
          <button className="save-popup" onClick={handlePathologyUpdate}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default PathologyCard;

// AddPathologyForm.jsx
import React, { useState, useEffect, useMemo } from "react";
import "./AddPathologyForm.css"; // Importing CSS for styling
import axios from "axios";

const AddPathologyForm = ({ isOpen, togglePopup }) => {
  // Memoize the initial form data so it's not recreated on every render
  const initialFormData = useMemo(() => ({
    pathologie: "",
    age_specifique_pathologie: "",
    sexe_specifique_pathologie: "",
    patho_associées: "",
    red_flags: "",
    green_flags: "",
    min_age_pathologie: "",
    max_age_pathologie: "",
    appareilId: "", 
  }), []); // Empty dependency array ensures it's only created once

  const [formData, setFormData] = useState(initialFormData);
  const [appareils, setAppareils] = useState([]);

  // Fetch appareils
  useEffect(() => {
    const fetchAppareils = async () => {
      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");

      try {
        const response = await axios.get("/appareils", {
          headers: {
            "x-access-token": token,
            Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
          },
        });
        setAppareils(response.data);
      } catch (error) {
        console.error("Error fetching appareils:", error);
      }
    };

    fetchAppareils();
  }, []);

  useEffect(() => {
    if (isOpen) {
      setFormData(initialFormData); // Reset form when the popup is opened
    }
  }, [isOpen, initialFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");

    try {
      await axios.post("/pathologies", formData, {
        headers: {
          "x-access-token": token,
          Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
        },
      });
      togglePopup(); // Close the popup after successful submission
    } catch (error) {
      console.error(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  if (!isOpen) return null; // Return null if the popup is not open

  return (
    <div className="popup-overlay" onClick={togglePopup}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <h3>Ajouter une nouvelle pathologie</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>
              Nom de la pathologie<span className="required">*</span>
              <input
                className="form-input"
                type="text"
                name="pathologie"
                value={formData.pathologie}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Age spécifique pathologie
              <select
                className="form-select"
                name="age_specifique_pathologie"
                value={formData.age_specifique_pathologie}
                onChange={handleChange}
              >
                <option value="">Choisir Age</option>
                <option value="A">Adulte</option>
                <option value="E">Enfant</option>
              </select>
            </label>
          </div>
          <div className="form-group">
            <label>
              Sexe spécifique pathologie
              <select
                className="form-select"
                name="sexe_specifique_pathologie"
                value={formData.sexe_specifique_pathologie}
                onChange={handleChange}
              >
                <option value="">Choisir Sexe</option>
                <option value="F">Femme</option>
                <option value="H">Homme</option>
              </select>
            </label>
          </div>
          <div className="form-group">
            <label>
              Pathologies associées
              <input
                className="form-input"
                type="text"
                name="patho_associées"
                value={formData.patho_associées}
                onChange={handleChange}
                placeholder="S'il y a plusieurs éléments, veuillez les séparer par un signe +."
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Red flags
              <input
                className="form-input"
                type="text"
                name="red_flags"
                value={formData.red_flags}
                onChange={handleChange}
                placeholder="S'il y a plusieurs éléments, veuillez les séparer par un signe +."
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Green flags
              <input
                className="form-input"
                type="text"
                name="green_flags"
                value={formData.green_flags}
                onChange={handleChange}
                placeholder="S'il y a plusieurs éléments, veuillez les séparer par un signe +."
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Minimum age pathologie
              <input
                className="form-input"
                type="text"
                name="min_age_pathologie"
                value={formData.min_age_pathologie}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Maximum age pathologie
              <input
                className="form-input"
                type="text"
                name="max_age_pathologie"
                value={formData.max_age_pathologie}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Appareil ID<span className="required">*</span>
              <select
                className="form-select"
                name="appareilId"
                value={formData.appareilId}
                onChange={handleChange}
                required
              >
                <option value="">Choisir Appareil</option>
                {appareils.map((appareil) => (
                  <option key={appareil._id} value={appareil._id}>
                    {appareil.appareil}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="helper">
            * Champs obligatoire
          </div>
          <div className="button-group">
            <button className="close-popup" onClick={togglePopup}>
              Fermer
            </button>
            <button type="submit" className="submit-button">
              Ajouter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPathologyForm;

import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogActions, DialogContent,DialogContentText, DialogTitle, Select, Menu, MenuItem, IconButton, Icon } from '@mui/material';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

function Dashboard() {
    const [open, setOpen] = useState(false);
    const [newUser, setNewUser] = useState({ username: "", email: "", password: "" });
    const [users, setUsers] = useState([]);
    const [lastStockUpdateDate, setLastStockUpdateDate] = useState({});
    const [originalUser, setOriginalUser] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [dialogMode, setDialogMode] = useState(null)
    const [isSubscribed, setIsSubscribed] = useState({});
    const [isInteractionSubscribed, setIsInteractionSubscribed] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    
    const handleClick = (event, userId) => {
      setAnchorEl(event.currentTarget);
      setSelectedUserId(userId);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      setSelectedUserId(null);
    };
  
    const handleOpenAddDialog = () => {
      setNewUser({ username: "", email: "", password: "", role: "" });
      setDialogMode("add");
      setOpen(true);
    };

    const handleOpenUpdateDialog = (user) => {
      setOriginalUser(user);
      setNewUser(user);
      setDialogMode("update");
      setOpen(true);
    };
  
    const handleUpdateUser = async () => {
      try {
        const updatePromises = [];
    
        // Check if the password has changed
        if (newUser.password !== originalUser.password) {
          updatePromises.push(
            axios.put('/reset_password', {
              userId: newUser._id,
              password: newUser.password
            }, {
              headers: { 
                "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
                Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`, 
              }
            })
          );
        }
    
        // Check if any other credentials have changed
        const credentialUpdates = {};
        if (newUser.username !== originalUser.username) {
          credentialUpdates.username = newUser.username;
        }
        if (newUser.email !== originalUser.email) {
          credentialUpdates.email = newUser.email;
        }
        if (newUser.phone_number !== originalUser.phone_number) {
          credentialUpdates.phone_number = newUser.phone_number;
        }
        if (newUser.roles !== originalUser.roles) {
          credentialUpdates.roles = newUser.roles;
        }
        if (newUser.taxRegistrationNumber !== originalUser.taxRegistrationNumber) {
          credentialUpdates.taxRegistrationNumber = newUser.taxRegistrationNumber;
        }
        if (newUser.userERP !== originalUser.userERP) {
          credentialUpdates.userERP = newUser.userERP;
        }
    
        // If there are any credential updates, add to the updatePromises
        if (Object.keys(credentialUpdates).length > 0) {
          updatePromises.push(
            axios.put('/update_user', {
              userId: newUser._id,
              ...credentialUpdates
            }, {
              headers: { 
                "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
                Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
              }
            })
          );
        }
    
        const responses = await Promise.all(updatePromises);
    
        responses.forEach((response) => {
          if (response.status === 200) {
            console.log(response.data.message);
          } else {
            console.error('Failed to update user');
          }
        });

        setUsers((prevUsers) =>
          prevUsers.map((user) => (user._id === newUser._id ? newUser : user))
        );

        setOpen(false);
        setOriginalUser(null);
    
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const handleDeleteClick = (userId) => {
      setSelectedUserId(userId);
      setIsDialogOpen(true);
    };
    
    const handleDeleteConfirm = async () => {
      setUsers(users => users.filter(user => user._id !== selectedUserId));
      await axios.post("delete_user", { userId: selectedUserId}, {
        headers: { 
          "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
          Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
        },
      });
      setIsDialogOpen(false);
      handleClose();
    };

    const handleDialogClose = () => {
      setIsDialogOpen(false);
    };
    
    const fetchLastStockUpdateDate = async (userId) => {
      try {
        const response = await axios.get(`/latest_stock_update/${userId}`, {
          headers: {
            "x-access-token": localStorage.getItem("token") || sessionStorage.getItem("token"),
            Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
          },
        });
        return response.data;
      } catch (error) {

        return null;
      }
    };
  
    useEffect(() => {
      const fetchUsers = async () => {
        try {
          const response = await axios.get("get_users", {
            headers: {
              "x-access-token": localStorage.getItem("token") || sessionStorage.getItem("token"),
              Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
            },
          });

          const usersWithSubscriptionStatus = response.data.map((user) => ({
            ...user,
            isSubscribed: user.hasOwnProperty("is_subscribed")
              ? user.is_subscribed
              : false,
          }));

          setUsers([...usersWithSubscriptionStatus].reverse());

          // Fetch the latest stock update date for each user
          const lastStockUpdateDatePromises = usersWithSubscriptionStatus.map(
            async (user) => {
              const lastStockUpdateDate = await fetchLastStockUpdateDate(user._id);
              
              return { userId: user._id, lastStockUpdateDate };
            }
          );

          // Wait for all promises to resolve
          const StockUpdateDates = await Promise.all(lastStockUpdateDatePromises);
          const userToLastStockUpdateDateMap = {};
          StockUpdateDates.forEach(({ userId, lastStockUpdateDate }) => {
            userToLastStockUpdateDateMap[userId] = lastStockUpdateDate || "-";
          });
          setLastStockUpdateDate(userToLastStockUpdateDateMap);

          const subscriptionStatuses = {};
          const interactionSubscriptionStatuses = {};
          usersWithSubscriptionStatus.forEach((user) => {
            subscriptionStatuses[user._id] = user.isSubscribed;
            interactionSubscriptionStatuses[user._id] =
              user.interaction_subscription;
          });
          setIsSubscribed(subscriptionStatuses);
          setIsInteractionSubscribed(interactionSubscriptionStatuses);
        } catch (error) {
          console.log(error);
        }
      };

      fetchUsers();
    }, []);
  
    const handleAddUser = async () => {
      const user = await axios.post("auth/signup", {...newUser, roles: [newUser.role], email: newUser.email.toLowerCase()}, {
        headers: { 
          "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
          Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
        },
      });
      setUsers([{...user.data, roles: [newUser.role]}, ...users]);
      setNewUser({ username: "", email: "", password: "", phone_number: "", role: "", taxRegistrationNumber: "", userERP: "" });
  
      // Close the dialog
      setOpen(false);
    };
  
    const handleActivation = async (user, isActive) => {
      try {
        await axios.put("users/activation", 
          { userId: user._id, isActive }, 
          {
            headers: { 
              "x-access-token": localStorage.getItem("token") || sessionStorage.getItem("token"),
              Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
            },
          }
        );
    
        if (isActive) {
          console.log(`User with ID ${user._id} has been activated.`);
        } else {
          console.log(`User with ID ${user._id} has been deactivated.`);
        }
    
        setIsSubscribed(prevState => ({ ...prevState, [user._id]: isActive }));
      } catch (error) {
        console.error(`Error ${isActive ? 'activating' : 'deactivating'} user:`, error);
      }
      handleClose();
    };
    const handleSendWelcomeEmail = async (user) => {
      // Ouvre une boîte de dialogue pour demander le mot de passe
      const password = window.prompt("Veuillez entrer un mot de passe pour l'envoyer dans l'email de bienvenue:");
    
      // Si l'utilisateur entre un mot de passe
      if (password) {
        // Confirmation supplémentaire pour envoyer l'email
        const isConfirmed = window.confirm(`Confirmez-vous l'envoi de l'email de bienvenue à cet utilisateur ?
          \nNom d'utilisateur : ${user.username}
          \nEmail : ${user.email}
          \nMot de passe : ${password}`);
        
        if (isConfirmed) {
          try {
            // Ajouter le mot de passe à l'objet utilisateur
            const userData = {
              email: user.email,
              password: password,
              username: user.username
            };
    
            const response = await axios.post('auth/welcome-email', userData, {
              headers: { 
                "x-access-token": localStorage.getItem("token") || sessionStorage.getItem("token"),
                Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
              }
            });
    
            if (response.status === 200) {
              console.log(`Email de bienvenue envoyé à l'utilisateur avec l'ID : ${user._id}`);
            } else {
              console.error("Échec de l'envoi de l'email de bienvenue");
            }
          } catch (error) {
            console.error("Erreur lors de l'envoi de l'email de bienvenue :", error);
          }
        } else {
          console.log("Envoi d'email annulé.");
        }
      } else {
        console.log("Aucun mot de passe saisi. Email non envoyé.");
      }
    
      handleClose();
    };
    const handleInteractionActivation = async (user, isActive) => {
      try {
        await axios.put("users/activate_interaction", 
          { userId: user._id }, 
          {
            headers: { 
              "x-access-token": localStorage.getItem("token") || sessionStorage.getItem("token"),
              Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
            },
          }
        );
    
        setIsInteractionSubscribed(prevState => ({ ...prevState, [user._id]: isActive }));
      } catch (error) {
        console.error(`Error with updating interaction user:`, error);
      }
      handleClose();
    };
    

  
    return (
      <div>
        <Typography variant="h4">Dashboard</Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenAddDialog()}
        >
          Add User
        </Button>

        {/* User Dialog Form */}
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>
            {dialogMode === "add" ? "Add New User" : "Update User"}
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Username"
              value={newUser.username}
              onChange={(e) =>
                setNewUser((prev) => ({ ...prev, username: e.target.value }))
              }
              style={{ marginBottom: "20px", marginTop: "20px" }}
            />
            <TextField
              fullWidth
              label="Email"
              value={newUser.email}
              onChange={(e) =>
                setNewUser((prev) => ({ ...prev, email: e.target.value }))
              }
              style={{ marginBottom: "20px" }}
            />
            <TextField
              fullWidth
              label="Phone Number"
              value={newUser.phone_number}
              onChange={(e) =>
                setNewUser((prev) => ({
                  ...prev,
                  phone_number: e.target.value,
                }))
              }
              style={{ marginBottom: "20px" }}
            />
            <TextField
              fullWidth
              type="password"
              label="Password"
              value={newUser.password}
              onChange={(e) =>
                setNewUser((prev) => ({ ...prev, password: e.target.value }))
              }
              style={{ marginBottom: "20px" }}
            />
            <Select
              fullWidth
              label="Role"
              value={newUser.role}
              onChange={(e) =>
                setNewUser((prev) => ({ ...prev, role: e.target.value }))
              }
              style={{ marginBottom: "20px" }}
            >
              <MenuItem value={"admin"}>Admin</MenuItem>
              <MenuItem value={"pharmacist"}>Pharmacist</MenuItem>
              <MenuItem value={"preparator"}>Preparator</MenuItem>
            </Select>
            <TextField
              fullWidth
              label="Matricule fiscale"
              value={newUser.taxRegistrationNumber}
              onChange={(e) =>
                setNewUser((prev) => ({
                  ...prev,
                  taxRegistrationNumber: e.target.value,
                }))
              }
              style={{ marginBottom: "20px" }}
            />
            <TextField
              fullWidth
              label="ERP"
              value={newUser.userERP}
              onChange={(e) =>
                setNewUser((prev) => ({ ...prev, userERP: e.target.value }))
              }
              style={{ marginBottom: "20px" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={dialogMode === "add" ? handleAddUser : handleUpdateUser}
              color="primary"
            >
              {dialogMode === "add" ? "Add" : "Update"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={isDialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this user?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Users Table */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone number</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell>Subscription</TableCell>
              <TableCell>Interaction Subscription</TableCell>
              <TableCell>Interaction Start Date</TableCell>
              <TableCell>Latest Stock Update</TableCell>
              <TableCell>Has Accepted Terms</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, idx) => (
              <TableRow key={idx}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone_number}</TableCell>
                <TableCell>{user.roles}</TableCell>
                <TableCell>
                  {user.is_subscribed ? "Subscribed" : "Not Subscribed"}
                </TableCell>
                <TableCell>
                  {user.interaction_subscription
                    ? "Subscribed"
                    : "Not Subscribed"}
                </TableCell>
                <TableCell>
                  {user.interaction_subscription_date_start
                    ? new Date(
                        user.interaction_subscription_date_start
                      ).toLocaleDateString("en-GB")
                    : "-"}
                </TableCell>
                <TableCell
                  style={{
                    color: (() => {
                      const date = new Date(lastStockUpdateDate[user._id]);
                      const today = new Date();

                      if (
                        isNaN(date.getTime()) ||
                        lastStockUpdateDate[user._id] === "-"
                      ) {
                        return "inherit";
                      }

                      const isSameDay =
                        date.getDate() === today.getDate() &&
                        date.getMonth() === today.getMonth() &&
                        date.getFullYear() === today.getFullYear();
                      return !isSameDay ? "#FA520D" : "inherit";
                    })(),
                  }}
                >
                  {(() => {
                    const date = new Date(lastStockUpdateDate[user._id]);
                    return !isNaN(date.getTime())
                      ? date.toLocaleString("en-GB")
                      : lastStockUpdateDate[user._id] || (
                          <CircularProgress size={32} thickness={4} />
                        );
                  })()}
                </TableCell>
                <TableCell
                  style={{ color: user.hasAgreedToTerms ? "black" : "red" }}
                >
                  {user.hasAgreedToTerms ? "Yes" : "No"}
                </TableCell>
                <TableCell>
                  <IconButton onClick={(e) => handleClick(e, user._id)}>
                    <Icon>...</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() =>
              handleOpenUpdateDialog(
                users.find((user) => user._id === selectedUserId)
              )
            }
          >
            Update
          </MenuItem>
          {isSubscribed[selectedUserId] ? (
            <MenuItem
              onClick={() =>
                handleActivation(
                  users.find((user) => user._id === selectedUserId),
                  false
                )
              }
            >
              Deactivate Subscription
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() =>
                handleActivation(
                  users.find((user) => user._id === selectedUserId),
                  true
                )
              }
            >
              Activate Subscription
            </MenuItem>
          )}
          {isInteractionSubscribed[selectedUserId] ? (
            <MenuItem
              onClick={() =>
                handleInteractionActivation(
                  users.find((user) => user._id === selectedUserId),
                  false
                )
              }
            >
              Deactivate Interation
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() =>
                handleInteractionActivation(
                  users.find((user) => user._id === selectedUserId),
                  true
                )
              }
            >
              Activate Interaction
            </MenuItem>
          )}
          <MenuItem
            onClick={() =>
              handleSendWelcomeEmail(
                users.find((user) => user._id === selectedUserId)
              )
            }
          >
            Send Welcome Email
          </MenuItem>{" "}
          {/* New action */}
          <MenuItem
            onClick={() =>
              handleDeleteClick(
                users.find((user) => user._id === selectedUserId)
              )
            }
          >
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }

  export default Dashboard;
